@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap');

a {
  text-decoration: none;
  color: unset;
}

body, html {
  background-color: rgb(51, 51, 51);
  color: rgb(255, 255, 255);
  font-family: 'Quicksand', sans-serif;
  height: 100%;
  margin: 0;
}

button {
  background-color: rgb(66, 66, 66);
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  font-size: fit-content;
  height: 40px;
  transition: .5s;
  width: 150px;
}

button:hover {
  background-color: #333333;
  color: white;
  transition: .5s;
}

footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

h1 {
  font-weight: 100;
}

hr {
  height: 1px;
  background-color: rgb(143, 143, 143);
  border: none;
  margin: 0;
}

input {
  background-color: #222;
  border: 1px solid rgb(163, 163, 163);
  color: rgb(209, 209, 209);
  font-size: 1em;
  outline: none;
  padding: 8px 10px;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #ffffff;
}

li {
  /* border: 1px solid rgb(206, 206, 206);
  border-radius: 5px; */
  padding: 0 10px;
}

main {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto; /* Allows scrolling if content overflows */
}

ul {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  /* flex-grow: 1; */
  margin-left: 250px;
  margin-top: 50px;
  padding: 0 30px;
  overflow-y: auto;
}

.emph {
  background-color: #4e4e4e;
  border-radius: 4px;
  padding: 0px 4px 2px 4px;
  transition: .5s;
}

.emph:hover {
  background-color: #dbdbdb;
  color: black;
  transition: .5s;
}

.example {
  margin-top: 35px;
}

.info-block {
  background-color: rgb(27, 26, 26);
  padding: 10px;
  margin-top: 20px;
}

.left-input {  
  border-radius: 5px 0 0 5px;
}

.right-input {
  border-radius: 0 5px 5px 0;
}

.round {
  border-radius: 10px;
}

.sharp {
  border-radius: 0;
}

.subtopic-selected {
  background-color: #30959c;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.welcome-msg {
  animation: welcomeAnimation 3s linear;
  position: absolute;
  user-select: none;
  white-space: nowrap;
  z-index: 0;
}

.welcome-page {
  position: relative;
  overflow: hidden;
  height: 100vh;
}

@keyframes welcomeAnimation {
  0% {
      transform: scale(0.2);
      opacity: 0;
  }
  35% {
      transform: scale(1);
      opacity: 0.7;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}



.triangle {
  display: inline-block;
}

.row {
  animation: fadeIn 1s forwards;
  /* opacity: 0; */
  /* position: absolute; */
  text-align: center;
  transition: left 1s;
  white-space: nowrap;
}

.cell {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin: 2px;
  font-family: Arial, sans-serif;
}

.circle-border {
  border: 1px solid #818181;
  border-radius: 50%;
  transition: .3s;
}

.circle-border:hover {
  background-color: #30959c;
  border-color: #30959c;
  cursor: pointer;
  transition: .5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {

  .content {
    margin-left: 0;
    padding: 0 15px;
  }

  .sidebar, .search-input, .search-button {
    display: none;
  }
}