

.array-item {
    background-color: aliceblue;
    border: 1px solid #000;
    border-radius: 5px;
    color: black;
    display: flex;
    flex-direction: column;
    height: 20px;
    margin: 5px;
    min-width: 30px;
    padding: 15px;
    text-align: center;
    transition: .5s;
}

.array-item:hover {
    background-color: rgb(53, 53, 53);
    border: 1px solid white;
    color: white;
    cursor: pointer;
    transition: .5s;
}

.array-item.selected {
    background-color: rgb(53, 53, 53);
    border: 1px solid white;
    color: white;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.controls button {
    border-radius: 0;
    color: white;
    outline: 1px solid rgb(141, 141, 141);
}

.controls input[type='number'] {
    height: 24px;
    width: 60px;
}

.controls-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    justify-items: flex-start;
}

.data-struct-visualization {
    align-items: center;
    background-color: #222222;
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.index-label {
    font-size: 12px;
    color: gray;
}

.linkedlist-node {
    display: flex;
    align-items: center;
    gap: 5px;
}

.node-double-pointer {
    font-size: 24px;
    margin-left: 4px;
}

.node-pointer {
    font-size: 24px;
    margin-right: 7px;
}

.stack {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #222222;
    border: 1px solid rgb(119, 119, 119);
    margin-top: 0px;
    padding: 10px;
    padding-left: 0px;
    width: 150px;
}

.stack .array-item {
    height: 20px;
    padding: 0;
    width: 100px;
}

.stack-node {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.data-struct-visualization.tree {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222222;
    border: 1px solid rgb(119, 119, 119);
    margin-top: 20px;
    padding: 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
/* region TREE */
.tree-node {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    position: relative;
}
  
.node-value {
    background-color: aliceblue;
    border: 1px solid #000;
    border-radius: 50%; /* Make the node circular */
    color: black;
    padding: 10px;
    text-align: center;
    min-width: 30px;
    width: 50px; /* Fixed width */
    height: 50px; /* Fixed height */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.children {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
}
  
.child {
    flex: 1;
    display: flex;
    justify-content: center;
}
  
.child::before {
    content: '';
    width: 2px;
    height: 20px;
    background-color: #000;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}
  
.tree-node .children::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
}
/* endregion TREE */


/* region SORT */
.iteration {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 20px; */
  }
  
  .node-pointer {
    font-size: 24px;
  }
  
  .algorithm-visualization {
    align-items: center;
    background-color: #222222;
    border: 1px solid rgb(119, 119, 119);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding: 10px;
  }
  
  .linkedlist-node {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .array-item {
    background-color: aliceblue;
    border: 1px solid #000;
    border-radius: 5px;
    color: black;
    display: flex;
    flex-direction: column;
    height: 20px;
    margin: 5px;
    min-width: 30px;
    padding: 15px;
    text-align: center;
    transition: .5s;
  }

.array-item.sorted {
    background-color: lightgreen; /* Different background color for sorted nodes */
  }
/* endregion SORT */


/* region PATTERN MATCHING */  
.grid-row {
    display: flex;
    /* margin-top: 10px; */
}

.grid-cell {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #ccc;
    line-height: 30px;
    text-align: center;
}

.grid-cell.index-label {
    border: 1px solid rgba(255, 255, 255, 0);
}

.grid-cell.grid-header {
    /* background-color: #f0f0f0; */
    font-weight: bold;
}

.grid-cell.matched {
    background-color: #67ff67;
    /* border-color: #28a745; */
    color: black;
}

.grid-cell.not-matched {
    background-color: #ff3d3d;
    /* border-color: #dc3545; */
    color: black;
}

.matches {
    margin-top: 20px;
    font-size: 1.2em;
    font-weight: bold;
}

.last-occurrence-table {
    margin-top: 20px;
}

.last-occurrence-table .grid-row {
    margin-bottom: 5px;
}

pre {
    background-color: #000;
    font-size: large;
}
/* endregion PATTERN MATCHING */