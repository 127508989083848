
.card {
    background-color: aliceblue;
    height: 100px;
    width: 170px;
}

.card-collection {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  justify-items: center;
  padding: 10px;
}

.group {
    background-color: #222222;
    border: 1px solid rgb(119, 119, 119);
    margin-bottom: 20px;
    padding: 25px;
    padding-top: 0;
    padding-bottom: 5px;
    transition: .3s;
}

.group:hover {
  border-color: rgb(192, 192, 192);
  /* border-color: #30959c; */
  border-radius: 8px;
  transition: .3s;
}

.group-container {
  /* display: flex;
  flex-wrap: wrap; */
  gap: 10px;
}