.header {
    align-items: center;
    background-color: #222;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    z-index: 10;
  }
  
  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .search-button {
    padding: 5px 15px;
    font-size: 1em;
    color: #fff;
    background-color: #7c7c7c;
    border: 1px solid rgb(163, 163, 163);
    border-left: none;
    border-radius: 0 5px 5px 0;
    height: 36px;
    width: 50px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .search-button:hover {
    background-color: #313131;
    border-color: #ffffff;
  }
  
  .search-form {
    display: flex;
    align-items: center;
  }

  .search-input {
    /* background-color: aqua; */
    width: 300px;
  }
  