.canvas {
    background-color: rgb(29, 29, 29);
    border: 2px solid white;
    height: 500px;
}

.content {
    margin-bottom: 50px;
}

.mobile-pullup-nav {
    display: none;
}

.mobile-pullup-nav.visible {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.733);
    bottom: 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    position: fixed;
    width: 100%;
}

.mobile-pullup-nav:hover {
    cursor: pointer;
}

.mobile-pullup-nav button {
    border-radius: 0;
    color: white;
    width: 70px;
}

.mobile-pullup-nav button:disabled {
    color: rgba(255, 255, 255, 0.144);
}

.music-staff {
  background-color: #ececec;
  height: 600px;
  /* margin: 0; */
  width: fit-content;
}

.sidebar {
    display: none;
    width: 100%;
}

.sidebar.visible {
    background-color: #222222;
    box-sizing: border-box;
    color: #fff;
    display: block;
    height: 94vh;
    margin-top: 0px;
    overflow-y: auto;
    position: fixed;
    margin-top: 45px;
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #30959c #222222; /* Firefox */
    z-index: 1;
}

.sidebar h2 {
  padding-left: 10px;
}

.sidebar button {
  border-radius: 0;
  color: white;
  transition: none;
  width: 100%;
} 

.sidebar button:hover {
  background-color: #30959c;
  transition: none;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track {
  background: #da2d2d;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #b81010;
}

.sidebar {
  scrollbar-gutter: stable;
}

.sidebar::-webkit-scrollbar {
  opacity: 0;
}

.sidebar:hover::-webkit-scrollbar {
  opacity: 1;
}

#study-topic {
    margin: 0;
}

@media screen and (min-width: 769px) {

    .mobile-pullup-nav, .mobile-pullup-nav.visible {
        display: none;
    }

    .sidebar, .sidebar.visible {
        background-color: #222222;
        /* box-sizing: border-box; */
        color: #fff;
        display: block;
        height: 94vh;
        margin-top: 0px;
        overflow-y: auto;
        position: fixed;
        margin-top: 56px;
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: #30959c #222222; /* Firefox */
        width: 250px;
        z-index: 1;
    }
}